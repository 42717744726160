import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../../components/Layout';
import CourseHightLights from '../../components/CourseHighlights';
import CoursePath from '../../components/CoursePath';
import CourseInstructors from '../../components/CourseInstructors';
import StudentProjects from '../../components/StudentProjects';
import GetStarted from '../../components/GetStarted';
import Tuition from '../../components/Tuition';

import PythonHeroImage from '../../images/svg/python-hero-image.svg';

const Python = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>{data.coursesJson.metadata.title}</title>
        <meta name="description" content={data.coursesJson.metadata.description} />
        <meta name="keywords" content={data.coursesJson.metadata.keywords} />
        <meta property="og:title" content={data.coursesJson.metadata.title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={data.coursesJson.metadata.description} />
        <meta property="og:image" content="" />
        <meta property="og:locale" content="en_us" />
        <meta property="og:site_name" content="Code N Hour" />
        <meta property="og:url" content={data.coursesJson.metadata.url} />
        <link rel="canonical" href={data.coursesJson.metadata.url} />
      </Helmet>
      <HeroBanner title={data.coursesJson.payload.hero.title} description={data.coursesJson.payload.hero.description}/>
      <CourseHightLights />
      <StudentProjects studentProjects= {data.coursesJson.payload.studentProjects} />
      <CoursePath payload={data.coursesJson.payload} />
      <Tuition data={data.coursesJson.payload.tuition} />
      <CourseInstructors instructors={data.coursesJson.payload.instructors} />
      <GetStarted title={data.coursesJson.payload.getStarted.title}
      subtitle={data.coursesJson.payload.getStarted.subtitle} />
    </Layout>
  )
}

export default Python;

export const query = graphql`
  {
    coursesJson(name: { eq: "python" }) {
      id
      name
      metadata {
        description
        keywords
        title
        url
      }
      payload {
        coursePath {
          title
          description
          levels {
            bgColor
            details
            subtitle
            summary
            title
          }
        }
        hero {
          description
          image
          title
        }
        tuition {
          amount
          frequency
        }
        instructors {
          id
          name
          profilePicUrl {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        studentProjects {
          title
          projects {
            grade
            student
            videoID
          }
        }
        getStarted {
          subtitle
          title
        }
      }
    }
  }
`;

const HeroBanner = ({title, description}) => {
  return (
    <section className="bg-cnh-accent-cello relative">
      <div className="hidden md:flex md:space-x-10 md:items-center mx-auto px-10 ">
        <div className="flex-1 flex justify-center py-48 ">
          <div className="w-96">
            <h1 className="text-4xl text-white mb-2 ">{title}</h1>
            <p className="text-base text-gray-300 ">{description}</p>
          </div>
        </div>
        <div className="flex-1">
          <PythonHeroImage />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center md:items-center space-y-10 md:space-y-0 md:space-x-10 mx-auto px-10 md:hidden">
        <PythonHeroImage className="flex-1 w-4/5 py-5" />
        <div className="flex-1 flex justify-center py-8 lg:py-40">
          <div className="w-96">
            <h1 className="text-4xl text-white mb-2 ">{title}</h1>
            <p className="text-base text-white">{description}</p>
          </div>
        </div>
      </div>
    </section>
  )
};